@import "App.scss";
@import "custom_bootstrap.scss";

div.filterBar {
  .customAccordion {
    border: $gray-border;
    border-radius: $border-radius-medium;
    box-shadow: none;
  }
}
