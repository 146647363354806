@import "../node_modules/bootstrap/scss/functions";

@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next"),
    url(./assets/fonts/AvenirNextFont.ttf) format("truetype");
}

// Colors
$white-1: rgb(250, 250, 250);
$orange-primary: rgb(255, 167, 0);
$turquoise-primary: rgb(0, 146, 157);
$graphite-primary: rgb(81, 98, 111);
$light-gray-background: rgb(245, 244, 244);
$gray-background: rgb(223, 223, 223);
$dark-background: rgb(15, 15, 15);
$black: rgb(0, 0, 0);

// Border color
$gray-border: 1px solid rgb(223, 223, 223);
$dark-gray-border: 1px solid rgb(194, 194, 194);

// Border radius
$border-radius-x-small: 4px;
$border-radius-small: 8px;
$border-radius-medium: 12px;
$border-radius-large: 16px;

// Box-shadow
$boxShadow-1: rgba(0, 0, 0, 0.25) 0px 54px 55px,
  rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
  rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
$boxShadow-2: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
  rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

// Overriding bootstrap variables
$font-family-sans-serif: Avenir Next;
$primary: $orange-primary;
$success: #7cb518;
$danger: #bf1a2f;
$info: $white-1;

// Nav Link
$navbar-light-color: $white-1;
$navbar-light-font-size: 24px;
$navbar-light-hover-color: rgba($orange-primary, 0.7);
$navbar-light-active-color: $orange-primary;
$navbar-link-color: rgba(0, 0, 0, 0);

// Modal
.modal-dialog {
  border-radius: $border-radius-small;
  box-shadow: $boxShadow-1;
}

.table {
  td {
    word-break: break-all;
  }
}

@import "../node_modules/bootstrap/scss/bootstrap";
