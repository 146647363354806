@import "custom_bootstrap.scss";
@import "App.scss";

.tableRow,
.tableColumn {
  cursor: pointer;
}

div.ordersTable,
div.checklistsTable,
div.usersTable,
div.companiesTable,
div.completedChecklistsTable {
  border: $gray-border;
  border-radius: $border-radius-medium;
  overflow: auto;

  /* Remove border-bottom on the last row */
  .lastRow td {
    border-bottom: none;
  }
}

div.completedChecklistsTable {
  border: $dark-gray-border;
}

div.usersTable .pending * {
  color: $danger;
  font-weight: bold;
}
