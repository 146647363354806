@import "App.scss";
@import "custom_bootstrap.scss";

.success {
  color: $success;
}

.danger {
  color: $danger;
}

p.createdAt {
  background-color: $gray-background;
  padding: 0.5rem;
  border-radius: $border-radius-small;
  font-size: 0.8rem;
  align-self: flex-start;
}

div.timeFromToTotal,
div.workHoursWithinInterval,
div.overTimeWithinInterval,
div.overTimeDetails {
  background-color: $gray-background;
  padding: 0.75rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: $border-radius-small;
  display: flex;
  flex-direction: column;
  width: 12rem;

  p {
    font-size: 0.8rem;
    margin: 0;
  }
}

div.overTimeWithinInterval {
  width: 210px;
}

div.overTimeDetails {
  padding: 1rem;
  width: 500px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3rem;

  p {
    font-size: 0.9rem;
  }
}

div.StatusComponent {
  display: inline-block;
  width: 8rem;

  p {
    text-align: center;
    padding: 0.25rem 0.75rem;
    font-weight: bold;
    font-size: 0.8rem;
    color: white;
    border-radius: $border-radius-small;
  }

  p.StatusComponentPending {
    background-color: $gray-800;
  }

  p.StatusComponentApproved {
    background-color: $success;
  }

  p.StatusComponentRejected {
    background-color: $danger;
  }
}

.customAccordion {
  border: $gray-border;
  box-shadow: none !important;
}
