@import "App.scss";
@import "custom_bootstrap.scss";

// Grey background color + border radius
div.completedInstallations,
div.companyInfo,
div.users,
div.reportSection,
div.dataContainer {
  background-color: $gray-background;
  border-radius: $border-radius-medium;
}

// Padding
div.companyInfo,
div.users,
div.reportSection,
div.dataContainer {
  padding: 20px;
}

div.companyInfo,
div.users {
  display: flex;
  flex-direction: column;
}

div.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2;
  border-radius: 0px 0px 4px 4px;
}

div.completedInstallations {
  padding: 2rem 1rem;
  margin-top: 1rem;
}

// Checklist data small boxes
div.userDataContainer {
  background-color: $light-gray-background;
  border-radius: $border-radius-small;
  padding: 10px;
  margin: 0.25rem;
}

div.dashboardContainer {
  background-color: white;
  border: $gray-border;
  border-radius: $border-radius-small;
  padding: 30px 0px 0px 0px;
  margin-left: 10px;
  width: 100%;
}
