@import "custom_bootstrap.scss";

.customModal {
  min-width: 92vw;
}

div.products {
  .accordionButton {
    background-color: $gray-background;
    border-radius: $border-radius-large;
    margin-bottom: 1rem;
  }

  .customAccordion {
    border-radius: 16px;
    box-shadow: none;
    background-color: transparent;
  }
}

div.product {
  background-color: $light-gray-background;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  line-height: 1;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 300px;
  min-width: 300px;
  border-radius: $border-radius-small;
  list-style: none;
}

.formContainer {
  margin: 2rem 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  min-height: 70vh;
}

// Edit product
div.editProductForm {
  background-color: $gray-background;
  padding: 1rem;
  border-radius: $border-radius-small;
  min-width: 300px;
  position: relative;

  input {
    margin: 0.5rem 0.25rem 1.25rem 0rem;
  }
  label {
    font-size: 0.9rem;
    font-style: italic;
    margin-bottom: 0;
    margin-left: 5px;
    position: relative;
    top: 5px;
  }
}

// Add new product
.newProductForm {
  min-width: 300px;
  width: 50%;
  background-color: rgba(220, 220, 220, 0.9);
  padding: 1rem;
  border-radius: $border-radius-small;

  input {
    margin: 0.5rem 0.25rem 1.25rem 0rem;
  }

  label {
    font-size: 0.9rem;
    font-style: italic;
    margin-bottom: 0;
    margin-left: 5px;
    position: relative;
    top: 5px;
  }
}

div.modalBody {
  min-height: 80vh;
}

div.confirmDeleteModal {
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  border-radius: $border-radius-small;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: 0px;
  justify-content: center;
  align-items: center;
  z-index: 1;

  p {
    color: white;
  }
}
