// Import custom bootstrap styles
@import "custom_bootstrap.scss";

// Colors
$white-1: rgb(250, 250, 250);
$gray: rgb(200, 200, 200);
$orange-primary: rgb(255, 167, 0);
$turquoise-primary: rgb(0, 146, 157);
$graphite-primary: rgb(81, 98, 111);
$turquoise-opacity: rgba(0, 146, 157, 0.3);

// Box-shadow
$boxShadow-1: rgba(0, 0, 0, 0.25) 0px 54px 55px,
  rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
  rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
$boxShadow-2: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
  rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

$boxShadow-3: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

body {
  background-image: url("./assets/avs-background.svg");
  background-size: cover;
}

@include media-breakpoint-between(lg, xxxl) {
  .scrollContainer {
    height: 100vh;
    overflow: scroll;
    display: flex;
    justify-content: center;
  }
}

@include media-breakpoint-between(xs, lg) {
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  border-radius: 2px;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

// Remove the up and down arrows from input type number
input[type="number"]::-webkit-inner-spin-button,
.quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Slideshow Carousel arrows
div.slideshow {
  a.carousel-control-prev,
  a.carousel-control-next {
    height: 30px;
    position: absolute;
    top: 50%;
  }

  a.carousel-control-prev {
    left: 0;
  }
  a.carousel-control-next {
    right: 0;
  }
}

/* styles.css */
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}
