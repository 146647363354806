@import "App.scss";
@import "custom_bootstrap.scss";

.link {
  color: $black;
  text-decoration: underline $orange-primary;

  &:hover {
    color: $black;
  }
}

.smallText,
p.orderInfoTitle,
div.postItem p {
  font-size: 0.9rem;
}

.smallText {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

p.orderInfoTitle {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

div.customerSummaryContainer,
div.contractData {
  p {
    font-weight: bold;
    margin-bottom: 0;

    span {
      font-weight: lighter;
    }
  }
}

div.postItem {
  p {
    margin: 0;
  }
}
