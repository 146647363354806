@import "App.scss";
@import "custom_bootstrap.scss";


.centerContainer {
  padding: 50px 50px 70px 50px;
  margin-bottom: 10vh;
  background-color: $white-1;
  border-radius: $border-radius-medium;
  box-shadow: $boxShadow-1;
}
