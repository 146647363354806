@import "custom_bootstrap.scss";

.thumbnailContainer {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  color: $black;
  cursor: pointer;
}

.thumbnailImage,
.thumbnailImageVideo,
.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.thumbnailImageVideo {
  opacity: 0.5;
}

.playIcon,
.playIconOverlay {
  max-width: 100%;
  max-height: 100%;
  padding: 2rem;
}

.playIconOverlay {
  position: absolute;
}
