@import "App.scss";
@import "custom_bootstrap.scss";

div.orderInfo {
  background-color: $gray-background;
  border-radius: $border-radius-medium;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.orderSummary,
  div.customerSummary,
  div.contractData {
    background-color: $light-gray-background;
    padding: 20px;
    margin: 10px;
    border-radius: $border-radius-small;
  }

  div.orderSummary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
