@import "App.scss";
@import "custom_bootstrap.scss";

.statusIcon,
.doneIcon,
.yesIcon,
.noIcon,
.notDoneIcon,
.houseIconGray,
.houseIconGreen {
  font-size: 2rem !important;
}

.doneIcon,
.yesIcon,
.addImageIcon,
.addCircleIcon,
.addIcon,
.commentIcon,
.houseIconGreen,
.greenCircle {
  color: $success;
}

.notDoneIcon,
.noIcon,
.deleteIcon,
.deleteCommentIcon,
.deleteProductIcon,
.clearIcon,
.cancelButton,
.redCircle {
  color: $danger;
}

.editIcon,
.grayCircle {
  color: $graphite-primary;
}

.houseIconGray {
  color: $gray-background;
}

.editIconOrange {
  color: $primary;
}

.greenCircle,
.grayCircle,
.redCircle {
  margin-right: 0.5rem;
}

// For delete comment (View Checklist)
.deleteCommentIcon {
  margin-left: 0.75rem;
  margin-top: 0.5rem;
  font-size: 3.5rem;
}

.deleteIcon,
.addCircleIcon,
.addImageIcon,
.deleteButton,
.deleteCommentIcon,
.deleteProductIcon,
.addIcon,
.commentIcon,
.clearIcon,
.editIcon,
.editIconOrange {
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
}

// Checklist Navigation
div.arrows {
  text-align: center;
  margin-bottom: 0.8rem;
  color: $primary;

  span {
    color: $black;
    margin: 0 10px;
  }

  svg {
    opacity: 0.75;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  svg.disabled {
    opacity: 0.4 !important;

    &:hover {
      opacity: 0.4 !important;
      cursor: default;
    }

    color: $graphite-primary;
  }
}

.eyeIcon {
  color: #161616;
  opacity: 0.4 !important;
  margin-top: 6px;
  font-size: 0.75rem;
}
