@import "App.scss";

.navItem {
  padding: 30px 0px 30px 10px;
  display: flex;
  align-items: center;
}

.navbarLogo {
  width: 100%;
}

.navBarContainer {
  background-color: rgba(20, 20, 20, 0.6);
}

.navItem {
  cursor: pointer;
  font-size: 16px;
  transition: 0.2s;

  &.active {
    background-color: rgba(220, 220, 220, 0.3);
    color: $orange-primary !important;
  }
}

@include media-breakpoint-up(lg) {
  .navBarContainer {
    height: 100%;
  }
  .navContainer {
    height: 100%;
  }
}

.logoutWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
