@import "App.scss";
@import "custom_bootstrap.scss";

// CONTAINERS ETC
.customModal {
  min-width: 92vw;

  p {
    color: white;
    margin-bottom: 0;
  }
}

.carouselItem {
  width: 90vw;
  height: calc(90vh - 90px);
}

.imageWrapper {
  width: 100%;
  height: calc(100% - 0px);
  margin: auto;
}

// NEW FEATURES SECTION
div.featuresSection {
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 0rem 2rem;
  min-width: 30vw;
}

.image {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}

// Grey border, border-radius, dark bg, 1rem padding
div.icons,
div.comment,
div.videoFeatures {
  border: $gray-border;
  border-radius: $border-radius-x-small;
  background-color: $dark-background;
  padding: 1rem;
  margin-bottom: 2px;
}

// Exkludera från PDF
div.icons,
div.videoFeatures {
  margin-bottom: 0.5rem;
}

div.comments {
  background-color: $gray-background;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  border-radius: $border-radius-x-small;
  max-height: 100%;
  overflow-y: auto; /* Enable vertical scroll */
}

p.includeLabel {
  margin-top: 8px;
  font-weight: 600;
}

// ICONS
div.icons {
  border-radius: $border-radius-x-small;
  display: flex;
  justify-content: space-between;
}

div.commentIconsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  justify-content: space-between;
  padding: 0.75rem;
}

div.comment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 90px;
  width: 100%;
  position: relative;
  z-index: 1;

  div.commentBy {
    p {
      font-size: 0.8rem;
      opacity: 0.75;
      margin-right: 2rem;
      margin-top: 1.5rem;
    }

    .link {
      color: $primary;
      text-decoration: none;
    }
  }
}

div.addComment,
div.addCommentBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

div.addCommentBox {
  width: auto;
  height: 58px;
}

div.confirmDelete,
div.writeComment {
  background-color: $gray-background;
  border-radius: $border-radius-small;
  padding: 2rem;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 90%;
  max-width: 700px;
}

div.createdBy {
  background-color: $gray-background;
  border-radius: $border-radius-x-small;
  padding: 1rem;
  margin-bottom: 0.5rem;

  p {
    color: $black;
  }
}

// VIDEO
div.videoPlayer {
  position: relative;
  margin-left: 2rem;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}

div.confirmDeleteComment {
  background-color: $dark-background;
  border-radius: $border-radius-small;
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .carouselItem {
    width: 50vw;
    height: calc(90vh - 90px);
  }

  div.featuresSection {
    height: calc(90vh - 90px);
    flex-direction: column;
    min-width: 40vw;
  }
}

@media only screen and (min-width: 992px) {
  .carouselItem {
    width: 60vw;
  }

  div.featuresSection {
    min-width: 30vw;
  }
}
