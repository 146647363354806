@import "App.scss";
@import "custom_bootstrap.scss";

div.confirmDelete {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);

  button {
    margin: 0.5rem;
  }

  p {
    color: white;
  }
}

// edit photo/video
div.imageWrapper {
  background-color: white;
  border-radius: 8px;
  margin: 1rem;
  padding: 1rem;
}

div.comment {
  background-color: $light-gray-background;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: $border-radius-small;
}

div.editCommentWrapper,
div.extraWrapper {
  background-color: $light-gray-background;
  margin: 0.5rem 0rem;
  padding: 1rem;
  border-radius: $border-radius-small;
}

.documentationSwitch {
  position: relative;
  top: -7px;
}

div.hideName {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.5rem;
}

.link {
  cursor: pointer;
}
