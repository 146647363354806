div.map {
  border-radius: 4px;
  height: 700px;
  width: 100%;
}

.searchBox {
  margin: 0.5rem;
  padding: 0.5rem;
  min-width: 200px;
  width: 30%;
  max-width: 500px;
  border-radius: 2px;
  border: transparent;
  font-size: 14px;
}

.accordionBody {
  padding: 1rem 0rem;
}

div.mapAccordion {
  .customAccordion {
    border: 1px solid rgb(223, 223, 223);
    border-radius: 12px;
    box-shadow: none;
  }
}
