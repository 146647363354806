@import "App.scss";
@import "custom_bootstrap.scss";

.mainContainer {
  padding: 35px 30px 55px 30px;
  margin-bottom: 10vh;
  background-color: $white-1;
  border-radius: 10px;
  box-shadow: $boxShadow-1;
  overflow-x: hidden;
}

.mainContainerWrapper {
  margin-top: 8vh;
  min-height: 500px;
  padding: 1rem;
  width: 100%;
}

